<!-- 品名管理-->
<template>
  <div>
        <ctrl-box class="ctrl-box">
            <template>
                <div class="xgroup">
                    <el-link :underline="false" @click="showQuery = !showQuery">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-search" font-size="27px;"/>
                            <span class="txt" >{{msg('搜索')}}</span>      
                        </div>                
                    </el-link>
                    <el-link :underline="false" @click="add()" v-if="hasPermission('simpleProductName:add')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-tianjia" font-size="27px;"/>
                            <span class="txt">{{msg('添加')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="edit()" v-if="hasPermission('simpleProductName:edit')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-bianji" font-size="27px;"/>
                            <span class="txt">{{msg('编辑')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doDelete()" v-if="hasPermission('simpleProductName:delete')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-shanchu1" font-size="27px;"/>
                            <span class="txt">{{msg('删除')}}</span>      
                        </div>
                    </el-link>
                </div>
            </template>
        </ctrl-box>
 
        <!--查询区域-->
        <transition name="el-zoom-in-top">
            <query-box :query="query" v-show="showQuery" labelWidth="80px">
                <template v-slot:default="scope">
                    <el-form-item :label="msg('品名')">
                        <el-input v-model="scope.query.name" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('替代名')">
                        <el-input v-model="scope.query.rename" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('品名类型')" >
                        <el-select v-model="scope.query.open" :placeholder="msg('请选择')" class="query-select">
                             <el-option v-for="d in dicts.isPublics" 
                                :label="d.label" :value="d.value" :key="d.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-row>
                        <el-button type="primary" icon="el-icon-search" @click="list" size="mini">{{msg('搜索')}}</el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="reset" >{{msg('重置')}}</el-button>
                    </el-row>
                </template>
            </query-box>
        </transition>

        <div class="table-wrap">
            <vxe-grid ref="dataTable" :height="docHeight" 
                export-config
                resizable border="full"
                show-overflow highlight-hover-row
                highlight-current-row highlight-hover-column highlight-current-column
                :data="productNames"
                :columns="columns"
                @cell-click="checkRow"
            ></vxe-grid>

            <pagenator @page-change="handlerPageChange" :query="query" >
            </pagenator>
        </div>

    <el-dialog :title="dgTitle" :visible.sync="dgVisible" :fullscreen="isMinScreen"  
        :close-on-click-modal="false" 
        width="600px" >
        <edit :productName="productNameEdit" ref="dgTitleRef" @close-dg="dgVisibleClose"/>
    </el-dialog>

    </div>  
</template>
<script>

import Pagenator from "../../components/Pagination.vue";
import CtrlBox from "../../components/CtrlBox.vue";
import QueryBox from "../../components/QueryBox.vue";
import Edit from "./ProductNameEdit.vue";

export default {
    name: 'simpleProductName',
    components:{
        Pagenator:Pagenator,
        CtrlBox: CtrlBox,
        QueryBox: QueryBox,
        Edit:Edit
    },
    mounted:function(){
        this.buildColumns();
        this.list();
    },
    data(){
        return {
            query:{
                length: 10,
                start: 0,
                pageIndex:1,
                pageTotal: 0,
                rename: null,
            },
            showQuery: false,
            curr: null,
            columns: [],
            productNames: [],
            dgTitle: '新增品名',
            dgVisible: false,
            productNameEdit:null,
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
            return this.$store.state.isMinScreen;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
    },
    methods: {
        buildColumns(){
            this.columns = [
                {type: 'checkbox', minWidth: 40},
                {field: 'id', title: this.msg('ID'), minWidth: 60, sortable: true},
                {field: 'name', title: this.msg('品名'), minWidth: 120, sortable: true},
                {field: 'rename', title: this.msg('替代名'), minWidth: 120, sortable: true},
                {field: 'open', title: this.msg('类型'), minWidth: 120, sortable: true,
                    slots: {
                            default: ({ row }) => {
                                let type = this.dicts.isPublics.find(d => d.value === row.open);
                                return [
                                    <el-tag type={type?type.type:''}>{type?type.label:row.open}</el-tag>
                                ];
                            },
                        }
                },
                {field: 'created', title: this.msg('创建时间'), minWidth:120, sortable: true},
                {field: 'creater', title: this.msg('创建ID'), minWidth: 120, sortable: true},
            ]
        },
        list(){
            var param = this.$kit.rebuildQuery(this.query);

            this.$axios.post(this.$kit.api.simpleProductName.list, param)
                .then((result) => {
                    if(result.status){
                        this.productNames = result.data.list;
                        this.query.pageTotal = result.data.total;
                    }else{
                        this.$message(result.msg || this.msg('获取失败'));
                    }
                });
        },
        //点击行，选中行
        checkRow({row, $event, column, columnIndex}){
            //如果点击checkbox 则不处理
            // console.log(columnIndex);
            // let className = $event.target.className;
           
            if(columnIndex > 0){  //选择 
                var table =  this.$refs.dataTable;
                table.setCheckboxRow([row], !table.isCheckedByCheckboxRow(row))
            }
        },
        reset(){
            this.$kit.clearQuery(this.query);
        },
        add(){
            this.productNameEdit = {
                id : null,
                name : '',
                rename:'',
                sku:'',
                skus: {"普":[""], "特":[""]}
            },
            this.dgVisible = true;
        },
        edit(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            if(selectes.length>1){
                this.$message(this.msg('只能编辑一条数据'));
                return;
            }

            this.productNameEdit = selectes[0];

            if(this.productNameEdit.sku){
                try{

                    let sku = JSON.parse(this.productNameEdit.sku);
                    sku["普"].push("");
                    sku["特"].push("");

                    this.$set(this.productNameEdit, 'skus', sku)
                }catch(err){
                    console.log(err);
                    this.$set(this.productNameEdit, 'skus', {"普":[""], "特":[""]})
                }
            }else{
                this.$set(this.productNameEdit, 'skus', {"普":[""], "特":[""]})
            }

            this.dgVisible = true;
        },
        doExport(){
            this.$refs.dataTable.openExport({ isPrint: false })
        },
        dgVisibleClose(){
            this.productNameEdit = {
                id : null,
                name : ''
            },
            this.dgVisible = false;
            this.list();
        },
        doDelete(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            var _this = this;
            this.$confirm(this.msg('确定要删除选中的数据吗?'), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                _this.$axios.post(_this.$kit.api.simpleProductName.delete+"?ids="+selectes.map(e => e.id).join(","))
                .then((result) => {
                    if(result > 0){
                        _this.$message(_this.msg('删除成功'));
                        _this.list();
                    }else{
                        _this.$message(_this.msg('只能删除自己创建的品名！'));
                    }
                });
            }).catch((err) => {
                // console.log(err);
            });
        },
        //分页数据发生改变，回调事件：info[0]: 每页显示的数量，info[1]: 起始页
        handlerPageChange(info){
            this.query.length = info[0];
            this.query.start = info[1];
            this.list();
        },
        closeEditDialog(type){  //编辑弹窗关闭后的回调事件
            if(type){
                this.list();
            }
            this.dgVisible = false;
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
