<!-- 异常通报 -->
<template>
    <div class="content-panel">
        <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
            <el-col :span="20">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/admin/stock'}">{{msg('库存管理')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{msg('异常通报')}}</el-breadcrumb-item>
                </el-breadcrumb>
            </el-col>
        </el-row>
        <el-form label-width="100px">
            <el-row style="margin-left: 2px;margin-right: 2px;">
                <el-col :xs="24" :sm="10">
                    <el-form-item :label="msg('订单编号')">
                        <el-input v-model="code" ref="codeRef" placeholder="请输入订单编号" 
                        size="medium" 
                        @keyup.native="autoSelectCode">
                            <i
                                class="el-icon-zoom-in el-input__icon"
                                slot="suffix"
                            >
                            </i>
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :xs="24" :sm="10">
                    <el-form-item :label="msg('异常类型')">
                            <el-input v-model="errCode" placeholder="请选择异常类型" size="medium">
                                <el-select v-model="errType" slot="prepend" placeholder="请选择" @change="selectChange">
                                    <el-option
                                        v-for="item in errTypes"
                                        :key="item.value" 
                                        :label="item.label"
                                        :value="item.value"> 
                                    </el-option>
                                </el-select>
                                <el-button slot="append" icon="el-icon-s-promotion" @click="errCodeEdit">批量修改</el-button>
                            </el-input>
                        </el-form-item>
                        <el-form-item :label="msg('异常说明')" style="text-align: right;">
                            <el-input v-model="errMsg" type="textarea" :rows="3" placeholder="请输入异常说明" 
                                maxlength="120" show-word-limit size="medium">
                            </el-input>
                            <el-button type="primary" icon="el-icon-s-promotion" size="mini" @click="errMsgEdit">批量修改</el-button>
                    </el-form-item>
                </el-col>
                <!-- <el-col :xs="24" :sm="10">
                    <el-form-item :label="msg('架位号')">
                        <el-input v-model="shelfCode" placeholder="请输入架位号" size="medium">
                            <el-button slot="append" icon="el-icon-s-promotion" @click="shelfCodeEdit">批量修改</el-button>
                        </el-input>
                    </el-form-item>
                </el-col> -->
            </el-row>
        </el-form> 
        <el-row :gutter="10"  class="ctrl-wrap" style="margin-left: 2px;margin-right: 2px;">

            <vxe-table :data="stocks"
                    export-config stripe
                    resizable border="full"
                    highlight-current-row highlight-hover-column highlight-current-column
                    show-overflow show-header-overflow 
                    max-height="400"
                    :scroll-y="{gt: 50}"
                    style="width: 100%" ref="dataTable" >
                <vxe-table-column type="seq" :title="msg('序号')" width="80" sortable></vxe-table-column>
                <vxe-table-column field="id" :title="msg('ID')" width="80" sortable></vxe-table-column>
                <vxe-table-column field="code" :title="msg('订单编号')" min-width="120" :show-overflow-tooltip="true"></vxe-table-column>
                <vxe-table-column field="errCode" :title="msg('异常类型')" min-width="150" >
                    <template v-slot="{ row }">
                        <el-select v-model="row.errCode" size="mini" placeholder="请选择">
                            <el-option
                                v-for="item in errTypes"
                                :key="item.value" 
                                :label="item.label"
                                :value="item.value"> 
                            </el-option>
                        </el-select>
                    </template>
                </vxe-table-column>
                <vxe-table-column field="errMsg" :title="msg('异常说明')" min-width="150" :show-overflow-tooltip="true">
                    <template v-slot="{ row }">
                        <el-input v-model="row.errMsg" placeholder="请输入异常说明" size="mini">
                        </el-input>
                    </template>
                </vxe-table-column>
                <vxe-table-column :title="msg('操作')" min-width="100">
                    <template v-slot="{ rowIndex }">
                        <el-button type="danger" class="nopad" icon="el-icon-delete" size="mini" plain
                            @click.stop="stocks.splice(rowIndex, 1)">
                        </el-button>
                    </template>
                </vxe-table-column>
            </vxe-table>

        </el-row>

        <div style="text-align:center; margin-top: 20px;margin-bottom: 20px;">
            <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('提交')}}</span></el-button>
            <el-button type="danger"  size="mini" @click="closeRoute"><i class="el-icon-close"></i><span>{{msg('关闭')}}</span></el-button>
            <el-button type="primary" size="mini" @click="createOrder"><i class="el-icon-close"></i><span>{{msg('转工单')}}</span></el-button>
        </div>

    </div>
</template>

<script>
export default {
  name: 'ExpPkgEdit',
  data(){
    return{
        stocks:[],
        ids:null,
        code:null,
        errTypes:[],
        errType:null,

        errCode:null,
        errMsg:null,
        shelfCode:null,

        inputing: false,
        prevCode:null,
    }
    
  },
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
      get() {
        var is = this.$store.state.isMinScreen;
        return is;
      }
    },
    docHeight: {  //表格的高度
      get() {
        var height =  this.$store.state.docHeight;
        return height - 220;
      }
    },
    routeTab: {
      get(){
        try{
          return this.$store.state.activeMenus[this.$route.path]
        }catch{
          return null;
        }
      }
    }
  },
  mounted(){
    // let prop = this.$route.params.ids;
    // this.ids = null;
    // if(prop){
    //     this.ids = prop;
    //     this.init();
    // }
    this.init();
    this.errTypes = this.dicts.errorTypes;
    this.prevCode = null;
    this.$refs['codeRef'].focus();
  },
  watch:{
      $route(){
        //跳转到该页面后需要进行的操作
        // let prop = this.$route.params.ids;
        // this.ids = null;
        // if(prop){
        //     this.ids = prop;
        //     this.init();
        // }
        this.init();
        this.errTypes = this.dicts.errorTypes;
        this.prevCode = null;
        this.$refs['codeRef'].focus();
      }
    },
  methods:{
    init(){
        this.ids = window.localStorage.getItem("errAddIds");
        this.$axios.post(this.$kit.api.stock.getErrList,this.ids)
        .then((result) =>{
            if(result.status){
                let data = result.data;
                if(data && data.length > 0){
                    this.stocks = data;
                }
            }
        })
    },
    autoSelectCode(e){

        if(e.keyCode == 13){
            this.selectStock();
        }

    },
    selectStock(){
        let has = false;
        if(this.stocks && this.stocks.length > 0){
            this.stocks.forEach(stock => {
                if(stock.code == this.code){
                    has = true;
                }
            })
        }
        if(!has){
            this.$axios.post(this.$kit.api.stock.getStockAndDetail+"?code="+this.code,)
            .then((result) =>{
                if(result.data){
                    let reCode = result.data.code;
                    let reId = result.data.id;
                    //添加一条记录到stocks,且不能重复
                    this.stocks.push({
                        id:reId,
                        code:reCode,
                        errCode:result.data.errCode,
                        errMsg:result.data.errMsg,
                        shelfCode:result.data.shelfCode,
                        owner:result.data.owner,
                    })
                    this.code = null;
                    this.$refs['codeRef'].focus();
                }else{
                    this.$message(this.msg('订单编号不存在'));
                    this.code = null;
                    this.$refs['codeRef'].focus();
                }
            });
        }else{
            this.$message(this.msg('已添加，不能重复添加'));
            this.code = null;
            this.$refs['codeRef'].focus();
        }
    },

    errCodeEdit(){
        var selectes = this.$refs.dataTable.selection;
        if(this.errCode){
            if(this.stocks.length > 0){
                if(selectes.length > 0){
                    //如果勾选，则只修改勾选的数据
                    selectes.forEach(select => {
                        this.stocks.forEach(stock => {
                            if(select.id == stock.id){
                                stock['errCode'] = this.errCode;
                            }
                        })
                    })
                }else{
                    //如果没勾选，则修改全部数据
                    this.stocks.forEach(e => {
                        e['errCode'] = this.errCode;
                    })
                }
            }else{
                this.$message(this.msg('请录入异常单号'));
            }
        }else{
            this.$message(this.msg('请选择异常类型！'));
        }
    },
    errMsgEdit(){
        var selectes = this.$refs.dataTable.selection;
        if(this.errMsg){
            if(this.stocks.length > 0){
                if(selectes.length > 0){
                    //如果勾选，则只修改勾选的数据
                    selectes.forEach(select => {
                        this.stocks.forEach(stock => {
                            if(select.id == stock.id){
                                stock['errMsg'] = this.errMsg;
                            }
                        })
                    })
                }else{
                    //如果没勾选，则修改全部数据
                    this.stocks.forEach(e => {
                        e['errMsg'] = this.errMsg;
                    })
                }
            }else{
                this.$message(this.msg('请录入异常单号'));
            }
        }else{
            this.$message(this.msg('请输入异常说明！'));
        }
    },
    shelfCodeEdit(){
        var selectes = this.$refs.dataTable.selection;
        if(this.shelfCode){
            if(this.stocks.length > 0){
                if(selectes.length > 0){
                    //如果勾选，则只修改勾选的数据
                    selectes.forEach(select => {
                        this.stocks.forEach(stock => {
                            if(select.id == stock.id){
                                stock['shelfCode'] = this.shelfCode;
                            }
                        })
                    })
                }else{
                    //如果没勾选，则修改全部数据
                    this.stocks.forEach(e => {
                        e['shelfCode'] = this.shelfCode;
                    })
                }
            }else{
                this.$message(this.msg('请录入异常单号'));
            }
        }else{
            this.$message(this.msg('请输入架位号！'));
        }
    },
    onSubmit(){
        var _this = this;
        if(this.stocks.length <= 0){
            this.$message(this.msg('请录入异常单号'));
            return;
        }
        this.stocks.forEach(e => {
            if(!e.errCode){
                this.$message(this.msg('请选择异常类型'));
                return;
            }
        })

        this.$axios.post(this.$kit.api.stock.stockErrorAdd,this.stocks)
            .then((result) =>{
                if(result.data){
                    _this.$message(result.msg || _this.msg('提交成功'));
                    _this.stocks.splice(0,_this.stocks.length);
                }else{
                    this.$message(_this.msg('提交失败'));
                }
            })

    },
    closeRoute(){
        this.$tabs.close("/admin/stock/error","/admin/stock");
    },
    selectChange(item){
        this.errCode = item;
    },
    createOrder(){
      if(this.stocks.length <= 0){
        this.$message(this.msg('请录入异常单号'));
        return;
      }
      let owners = new Set();
      let typeFlag = true;
      this.stocks.forEach(e => {
        if (!e.errCode) {
          typeFlag = false;
          return false;
        }
        owners.add(e.owner);
      })
      if(!typeFlag){
        this.$message(this.msg('请选择异常类型'));
        return;
      }

      if (owners.size <= 0) {
        this.$message(this.msg('所选工单缺少归属人信息'));
        return;
      }
      var workorders =[];
      for (let item of owners){
        let orders = this.stocks.filter(a => a.owner == item);
        var typeName = '客服咨询';
        var title = orders[0].errCode + ":" + (orders[0].errMsg || '');
        var orderNos = orders.map(a => a.code).join(",");
        var obj = {
          typeName: typeName,
          title: title,
          description: title+'；订单编号：' + orderNos,
          level: 0,
          handlerId: item
        }
        workorders.push(obj);
      }
      var url = this.$kit.api.workorder.createbatch;
      var _this = this;
      this.$confirm('确定要转工单吗?', this.msg('提示'), {
        confirmButtonText: this.msg('确定'),
        cancelButtonText: this.msg('取消'),
        type: 'warning'
      }).then(() => {
        _this.$axios.post(url, workorders)
            .then(function (result) {
              if (result.status) {
                _this.createOrderFinish(true);
              } else {
                _this.$message(result.msg || _this.msg("转工单失败"));
              }
            });
      });
    },
    createOrderFinish(result){
      if(result){
        this.$message(this.msg('转工单成功'));
        this.closeRoute();
      }
    }
  }
}
</script>
<style scoped lang="scss">
.xmain .router-tab-page {
    height: calc(100vh - 100px);
}
</style>
