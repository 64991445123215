<template>
    <div class="content-panel">
        <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
            <el-col :span="20">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/admin/stock'}">{{msg('库存管理')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{msg('扫码入库')}}</el-breadcrumb-item>
                </el-breadcrumb>
            </el-col>
        </el-row>
        <el-form label-width="100px">
            <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap" 
                style="margin-left: 2px;margin-right: 2px;padding-top:20px">
                <el-col :xs="12" :sm="12">
                    <el-form-item>
                        <el-checkbox v-model="checkedType1" @change="checBox1Change">订单数量</el-checkbox>
                        <el-checkbox v-model="checkedType2" @change="checBox2Change">指定数量</el-checkbox>
                        <el-input-number v-model="numType2" controls-position="right" :min="0" size="mini"></el-input-number>件
                        <el-checkbox v-model="locked">锁定</el-checkbox>
                    </el-form-item>
                    <el-form-item :label="msg('单号')">
                        <el-input v-model="checkCode" ref="checkCodeRef" placeholder="订单编号/包号/袋号/库存编号" size="medium" @keyup.native="autoCheckCode"></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('备注')">
                        <el-input v-model="remark" placeholder="请输入备注"></el-input>
                    </el-form-item>
                    <el-tag type="danger">提示：订单数量模式是将订单编号/包号/袋号详情中的数量加入到对应的库存中；指定数量模式为在对应的库存加入指定的库存数量；</el-tag>
                    <el-button style="margin-left:10px" type="success" size="mini" @click="clearTable">清空列表</el-button>
                </el-col>
            </el-row>
        </el-form> 
        <el-row :gutter="10"  class="ctrl-wrap" style="margin-left: 2px;margin-right: 2px;">
            <el-table :data="details" style="width: 50%;" size="mini" ref="dataTable" :key="tableKey" border stripe>
                <el-table-column prop="index" type="index" sortable :label="msg('序号')" width="80"></el-table-column>
                <el-table-column prop="code" sortable :label="msg('单号')" min-width="100"></el-table-column>
                <el-table-column prop="msg" :label="msg('结果')" min-width="100"></el-table-column>
            </el-table>

        </el-row>

        <div style="display:inline-block; width:1px; height:1px; overflow:hidden;">
            <audio controls="controls" preload ref="audioRef" >
            </audio>
        </div>

    </div>
</template>

<script>
import Warning from "../../assets/media/warning.wav";
import OkMp3 from "../../assets/media/ok.mp3"; //成功
import NoRecord from "../../assets/media/NoRecord.mp3";//订单编号不存在
export default {
  name: 'ExpPkgEdit',
  data(){
    return{
        code:null,
        checkCode:null,
        details:[],
        tableKey:0,
        audioUrl:null,
        checkedType1:false,
        checkedType2:false,
        numType2:0,
        locked:false,
        remark:null,
    }
    
  }, 
  computed:{
  },
  mounted: function(){
        this.code = null;
        this.checkCode = null;
        this.details = [];
        this.tableKey = 0;
        this.$refs['checkCodeRef'].focus();
  },
  methods:{
    autoCheckCode(e){
        if(e.keyCode == 13){
            this.autoSubmit();
        }
    },
    playAudio(){
        let audio = this.$refs.audioRef;
        audio.src = this.audioUrl;
        audio.play();
    },
    checBox1Change(){
        if(this.checkedType1){
            this.checkedType2 = false;
        }
    },
    checBox2Change(){
        if(this.checkedType2){
            this.checkedType1 = false;
        }
    },
    clearTable(){
        this.details = [];
    },
    autoSubmit(){

        if(!this.checkedType1 && !this.checkedType2){
            this.$message(this.msg('请选择模式'));
            return;
        }

        if(this.checkedType2 && !this.numType2){
            this.audioUrl = Warning;
            this.playAudio();
            this.checkCode = null;
            this.$refs['checkCodeRef'].focus();
            return;
        }

        let type = null;
        if(this.checkedType1){
            type = 1;
        }else if(this.checkedType2){
            type = 2;
        }
        let para = {
            code : this.checkCode,
            type : type,
            remark : this.remark,
            qty : this.numType2
        }
        var _this = this;
        this.$axios.post(this.$kit.api.stock.checkCodeInStore, para)
            .then((result) =>{
                let data = result.data;
                console.log(result);
                if(data.msg.indexOf('不存在') > -1){
                    this.audioUrl = NoRecord;
                    this.playAudio();
                    this.checkCode = null;
                    this.$refs['checkCodeRef'].focus();
                }else if(data.msg.indexOf('成功') > -1){
                    this.audioUrl = OkMp3;
                    this.playAudio();
                    this.checkCode = null;
                    this.$refs['checkCodeRef'].focus();
                }else{
                    this.audioUrl = NoRecord;
                    this.playAudio();
                    this.checkCode = null;
                    this.$refs['checkCodeRef'].focus();
                }
                if(this.checkedType2 && !this.locked){
                    this.numType2 = 0;
                }
                if(this.checkedType1){
                    this.locked = false;
                    this.numType2 = 0;
                }
                this.details.push(data);
            });
    },
  }
}
</script>

<style scoped lang="scss">

</style>
