<template>
    <div class="content-panel">
        <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
            <el-col :span="20">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/admin/stock'}">{{msg('库存管理')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{msg('出货核对')}}</el-breadcrumb-item>
                </el-breadcrumb>
            </el-col>
        </el-row>
        <el-form label-width="100px">
            <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap" 
                style="margin-left: 2px;margin-right: 2px;padding-top:20px">
                <el-col :xs="24" :sm="10">
                    <el-form-item :label="msg('订单编号')">
                        <el-input v-model="code" ref="codeRef" placeholder="请输入订单编号" size="medium" @keyup.native="autoSelectCode">
                            <i
                                class="el-icon-search el-input__icon"
                                slot="suffix"
                            >
                            </i>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="msg('待核对单号')">
                        <el-input v-model="checkCode" ref="checkCodeRef" placeholder="请输入待核对单号" size="medium" @keyup.native="autoCheckCode"></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('待核对数量')">
                        <el-tag>{{count}}</el-tag>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form> 
        <el-row :gutter="10"  class="ctrl-wrap" style="margin-left: 2px;margin-right: 2px;">
            <el-table :data="details" style="width: 100%;" size="mini" ref="dataTable" :key="tableKey" border stripe>
                <el-table-column prop="index" type="index" :label="msg('序号')" width="50"></el-table-column>
                <el-table-column prop="productTitle" :label="msg('商品名')" min-width="120"></el-table-column>
                <el-table-column prop="productSpec" :label="msg('规格')" min-width="100"></el-table-column>
                <el-table-column prop="productQty" :label="msg('数量')" min-width="80"></el-table-column>
                <el-table-column prop="innerExpCode" :label="msg('国内快递单号')" min-width="120"></el-table-column>
                <el-table-column prop="result" :label="msg('提示')" min-width="100">
                    <template slot-scope="scope">
                        <el-tag effect="dark" type="success" v-if="scope.row.result == 1 ">匹配成功</el-tag>
                        <el-tag effect="dark" type="warning" v-else>待匹配</el-tag>
                    </template>
                </el-table-column>
            </el-table>

        </el-row>

        <div style="display:inline-block; width:1px; height:1px; overflow:hidden;">
            <audio controls="controls" preload ref="audioRef" >
            </audio>
        </div>

    </div>
</template>

<script>
import allCheck from "../../assets/media/allCheck.mp3";//核对完成，打包完成
import allCheckErr from "../../assets/media/allCheckErr.mp3";//核对完成，打包失败
import checkErr from "../../assets/media/checkErr.mp3";//匹配失败
import checkSuccess from "../../assets/media/checkSuccess.mp3";//匹配成功
import noCode from "../../assets/media/noCode.mp3";//订单编号不存在
import noDetail from "../../assets/media/noDetail.mp3";//详情不存在
import rightCode from "../../assets/media/rightCode.mp3";//订单编号正确
export default {
  name: 'ExpPkgEdit',
  data(){
    return{
        code:null,
        checkCode:null,
        count:0,
        details:[],
        inputing: false,
        prevCode:null,
        inputing1: false,
        prevCode1:null,
        tableKey:0,
        audioUrl:null,
    }
    
  }, 
  computed:{
  },
  mounted: function(){
        this.code = null;
        this.checkCode = null;
        this.count = 0;
        this.details = [];
        this.prevCode = null;
        this.prevCode1 = null;
        this.tableKey = 0;
        this.$refs['codeRef'].focus();
  },
  methods:{
    autoSelectCode(){
        if(this.inputing == false){
            this.inputing = true;
            var _this = this;
            var timer = setInterval(() => {
                 
                if(_this.prevCode == _this.code){
                    clearInterval(timer);
                    _this.selectStock();
                    _this.inputing = false;

                }else{
                    _this.prevCode = _this.code;
                }

            }, 500);
        }
    },
    playAudio(){
        let audio = this.$refs.audioRef;
        audio.src = this.audioUrl;
        audio.play();
    },
    selectStock(){
        var _this = this;
        this.$axios.post(this.$kit.api.stock.getStockAndDetail+"?code="+this.code,)
            .then((result) =>{
                if(result.data){
                    _this.code = result.data.code;
                    _this.count = result.data.stockDetails.length;
                    _this.details = result.data.stockDetails;
                    if(_this.count > 0){
                        _this.audioUrl = rightCode;
                        _this.playAudio();
                        _this.$refs['checkCodeRef'].focus();
                    }else{
                        // _this.$message(_this.msg('货单详情为空'));
                        _this.audioUrl = noDetail;
                        _this.playAudio();
                        _this.code = null;
                        _this.$refs['codeRef'].focus();
                    }
                }else{
                    _this.audioUrl = noCode;
                    _this.playAudio();
                    _this.count = 0;
                    _this.checkCode = null;
                    _this.details = [];
                    // _this.$message(_this.msg('订单编号不存在'));
                    _this.$refs['codeRef'].focus();
                }
            });
    },
    autoCheckCode(){
        if(this.inputing1 == false){
            this.inputing1 = true;
            var _this = this;
            var timer = setInterval(() => {
                
                if(_this.prevCode1 == _this.checkCode){
                    clearInterval(timer);
                    _this.checkDetail();
                    _this.inputing1 = false;

                }else{
                    _this.prevCode1 = _this.checkCode;
                }

            }, 500);
        }
    },
    checkDetail(){
        var _this = this;
        if(this.details.length > 0){
            //判断待核对单号和详情的单号是否一致，如果一致则语音提示匹配成功，不一致则语音提示不存在
            let has = false;
            this.details.forEach( d => {
                if(d.innerExpCode && this.checkCode === d.innerExpCode){
                    d.result = 1;
                    has = true;
                    _this.audioUrl = checkSuccess;
                    _this.changeStatus(d.innerExpCode);
                    _this.checkCode = null;
                    _this.playAudio();
                    this.tableKey += 1;
               }
            })
            if(!has){
                this.checkCode = null;
                this.$refs['checkCodeRef'].focus();
                _this.audioUrl = checkErr;
                _this.playAudio();
            }

            //每次核对单号时判断是否全部匹配完成，如果完成则将该订单状态变成已打包
            let isAllOk = true;
            this.details.forEach( e => {
                if(e.result != 1){
                    isAllOk = false;
                }
            } )
            if(isAllOk){
                _this.$axios.post(_this.$kit.api.updateColumn.stockCheck+"?id="+this.details[0].stockId)
                .then((result) => {
                    if(result.status){
                        setTimeout(function(){
                             _this.audioUrl = allCheck;
                            _this.playAudio();
                            _this.code = null;
                            _this.checkCode = null;
                            _this.$refs['codeRef'].focus();
                        },1000)
                    }else{
                        setTimeout(function(){
                            _this.audioUrl = allCheckErr;
                            _this.playAudio();
                            _this.code = null;
                            _this.checkCode = null;
                            _this.$refs['codeRef'].focus();
                        },1000)
                    }
                });
            }
        }else{
            // this.$message(_this.msg('货单详情为空'));
            this.$refs.noDetail.play();
        }
    },
    changeStatus(code){
        this.$axios.post(this.$kit.api.updateColumn.stockCheckUpdateStatus+"?code="+code)
            .then((result) => {
                if(result.status){
                    console.log('成功');
                }
            });
    }
  }
}
</script>

<style scoped lang="scss">

</style>
