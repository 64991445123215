<!-- 品名管理编辑-->
<template>
    <el-form ref="form" :model="productName" label-width="100px" style="margin:0 20px">
        <el-row>
            <el-col :xs="24" :sm="18">
                <el-form-item :label="msg('品名')">
                    <el-input v-model="productName.name" :placeholder="msg('请输入品名')" size="small"></el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="18">
                <el-form-item :label="msg('替代名')">
                    <el-input v-model="productName.rename" :placeholder="msg('请输入替代名')" size="small"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row v-if="productName && productName.skus" class="ctrl-wrap">
            <h3 style="margin: 8px 15px;">{{msg("货物类型设置")}}</h3>
            <el-col :xs="24" :sm="12" v-for="(val, key, idx) in productName.skus" :key="idx">
                <p style="text-align:center; font-size: 16px; font-weight:bold; color:#666;">{{key}}</p>
                <el-form-item :label="msg('规格')" label-width="60px" v-for="(spec, idx1) in val" :key="idx1" style="margin-bottom: 10px;">
                    <el-input v-model="val[idx1]" :placeholder="msg('请输入规格')" size="mini">
                        <el-button slot="append" icon="el-icon-delete" 
                                @click.stop="val.splice(idx1, 1)">
                        </el-button>
                    </el-input>
                </el-form-item>
                <div class="cell-tool" style="text-align:center;">
                    <el-button type="primary" @click="() => val.push('')" plain size="mini" >添加更多</el-button>
                </div>
            </el-col>
        </el-row>
        <div style="text-align:center; margin-top: 15px;">
            <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确认')}}</span></el-button>
            <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
        </div>

    </el-form>

</template>

<script>
export default {
    name: "productNameEdit",
    components:{

    },
    data(){
        return {
            
        }
    },
    mounted(){
    },
    props:{
        productName:Object,
    },
    methods:{
        onSubmit(){
            var _this = this;

            let param = JSON.parse(JSON.stringify(this.productName));
            delete param.skus;
            delete param["created"];
            delete param["creater"];
            delete param['ownerName']

            if(this.productName.skus){

                var skus = {
                    "普":[],
                    "特":[]
                };

                for(var key in this.productName.skus){
                    let ss = this.productName.skus[key];
                    for(let i = 0; i<ss.length; i++){
                        let spec = ss[i];
                        if(spec){
                            skus[key].push(spec);
                        }
                    }
                }

                param['sku'] = JSON.stringify(skus);
            }

            var url = null;
            if(this.productName.id==null){
                url = this.$kit.api.simpleProductName.create;
            }else{
                url = this.$kit.api.simpleProductName.update;
            }

            this.$axios.post(url,param)
                .then(function(result){
                    _this.$message(result.msg || _this.msg('update-ok'));
                    _this.$emit("close-dg", true);
                });
        },
    }

}
</script>

<style lang="scss" scoped>

</style>